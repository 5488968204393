/**
 * @generated SignedSource<<083131c03d8a2e6d2c3a42b50a5f1240>>
 * @relayHash cee9e2c2be9e5ce9258dcdba21c4a225
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 30065855ae1c18a3183e789cbf955fae2e1461dd

import { ConcreteRequest, Query } from 'relay-runtime';
export type applicantportalQuery$variables = {};
export type applicantportalQuery$data = {
  readonly session: {
    readonly sub: string | null;
  } | null;
  readonly openIntake: {
    readonly openTimestamp: any;
    readonly closeTimestamp: any;
    readonly rollingIntake: boolean | null;
  } | null;
  readonly nextIntake: {
    readonly openTimestamp: any;
  } | null;
};
export type applicantportalQuery = {
  variables: applicantportalQuery$variables;
  response: applicantportalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "KeycloakJwt",
  "kind": "LinkedField",
  "name": "session",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sub",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openTimestamp",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeTimestamp",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rollingIntake",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "applicantportalQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Intake",
        "kind": "LinkedField",
        "name": "openIntake",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Intake",
        "kind": "LinkedField",
        "name": "nextIntake",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "applicantportalQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Intake",
        "kind": "LinkedField",
        "name": "openIntake",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Intake",
        "kind": "LinkedField",
        "name": "nextIntake",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "30065855ae1c18a3183e789cbf955fae2e1461dd",
    "metadata": {},
    "name": "applicantportalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4ab7ab3942ddda4f1e178435a69a3d3d";

export default node;
